/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const GlobeIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.5 12C1.5 6.201 6.201 1.5 12 1.5c4.505 0 8.347 2.837 9.838 6.822.428 1.145.662 2.384.662 3.678 0 1.291-.233 2.528-.66 3.671l-.003.008c-1.372 3.667-4.735 6.362-8.774 6.768a10.643 10.643 0 01-2.12 0h-.006C5.637 21.914 1.5 17.44 1.5 12zm19.12-4a9.517 9.517 0 00-6.596-5.284c.272.466.604 1.093.935 1.86.405.94.811 2.093 1.104 3.424h4.556zm-4.365 1h4.762a9.49 9.49 0 01.483 3 9.49 9.49 0 01-.483 3h-4.762a18.53 18.53 0 000-6zm-1.217-1a17.932 17.932 0 00-.997-3.027 16.114 16.114 0 00-1-1.953c-.13-.215-.239-.38-.315-.493a9.633 9.633 0 00-1.452 0c-.076.113-.185.278-.315.493a16.12 16.12 0 00-1 1.953A17.942 17.942 0 008.962 8h6.076zm-6.28 1h6.483a17.535 17.535 0 010 6H8.76a17.54 17.54 0 010-6zm-.821-1A18.942 18.942 0 019.04 4.577c.331-.768.663-1.395.935-1.86A9.518 9.518 0 003.381 8h4.556zM2.983 9h4.762a18.532 18.532 0 000 6H2.983a9.49 9.49 0 01-.483-3c0-1.048.17-2.057.483-3zm11.058 10.027A17.94 17.94 0 0015.038 16H8.962c.273 1.17.635 2.188.997 3.027a16.12 16.12 0 001 1.954c.13.214.238.38.315.492a9.641 9.641 0 001.452 0c.077-.113.185-.278.315-.492.271-.448.635-1.109 1-1.954zM7.937 16H3.38a9.518 9.518 0 006.595 5.284 17.414 17.414 0 01-.935-1.86A18.943 18.943 0 017.937 16zm12.682 0h-4.556a18.944 18.944 0 01-1.104 3.424 17.407 17.407 0 01-.935 1.86A9.518 9.518 0 0020.62 16z"
			fill="currentColor"
		/>
	</svg>
))
GlobeIconSvg.displayName = 'GlobeIconSvg'

const GlobeIcon = forwardRef((props, ref) => <Icon component={GlobeIconSvg} ref={ref} {...props} />)
GlobeIcon.displayName = 'GlobeIcon'

GlobeIcon.defaultProps = {
	...Icon.defaultProps,
}
GlobeIcon.propTypes = {
	...Icon.propTypes,
}

export default GlobeIcon
export { GlobeIconSvg }
