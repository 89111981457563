/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const HeartIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.305 3.5c-1.38 0-2.705.568-3.68 1.579l-.265.275a.5.5 0 01-.72 0l-.265-.275a5.08 5.08 0 00-7.361 0c-2.019 2.092-2.019 5.475 0 7.567l7.295 7.562a.963.963 0 001.35.03c1.684-1.62 3.286-3.322 4.896-5.032.805-.855 1.612-1.711 2.431-2.56A5.45 5.45 0 0021.5 8.862a5.45 5.45 0 00-1.514-3.783 5.113 5.113 0 00-3.68-1.579zM12 4.288A6.108 6.108 0 0116.305 2.5c1.657 0 3.24.681 4.4 1.884A6.45 6.45 0 0122.5 8.862a6.45 6.45 0 01-1.794 4.478M12 4.288a6.08 6.08 0 00-8.706.096c-2.392 2.48-2.392 6.477 0 8.956l7.295 7.562c.746.773 1.981.8 2.76.06l.003-.002c1.7-1.637 3.334-3.372 4.954-5.093.801-.85 1.598-1.697 2.4-2.527"
			fill="currentColor"
		/>
	</svg>
))
HeartIconSvg.displayName = 'HeartIconSvg'

const HeartIcon = forwardRef((props, ref) => <Icon component={HeartIconSvg} ref={ref} {...props} />)
HeartIcon.displayName = 'HeartIcon'

HeartIcon.defaultProps = {
	...Icon.defaultProps,
}
HeartIcon.propTypes = {
	...Icon.propTypes,
}

export default HeartIcon
export { HeartIconSvg }
