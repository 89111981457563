/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SupportIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.5 11a8.5 8.5 0 0117 0 .5.5 0 01-.011.107l.117.03a2.5 2.5 0 011.894 2.425v1.876a2.5 2.5 0 01-1.894 2.426l-.544.136h.438v.5A2.5 2.5 0 0118 21h-2.563a2 2 0 01-1.937 1.5h-3a2 2 0 010-4h3a2 2 0 011.937 1.5H18a1.5 1.5 0 001.5-1.5v-.36l-.633.159a1.1 1.1 0 01-1.367-1.067v-5.463a1.1 1.1 0 011.367-1.068l.632.158a7.5 7.5 0 00-14.998 0l.632-.158A1.1 1.1 0 016.5 11.768v5.463A1.1 1.1 0 015.133 18.3l-1.74-.435A2.5 2.5 0 011.5 15.438v-1.876a2.5 2.5 0 011.894-2.426l.117-.03A.501.501 0 013.5 11zm11 9.5a1 1 0 00-1-1h-3a1 1 0 100 2h3a1 1 0 001-1zm-9-8.732a.1.1 0 00-.124-.097l-1.74.435A1.5 1.5 0 002.5 13.562v1.876a1.5 1.5 0 001.136 1.456l1.74.435a.1.1 0 00.124-.098v-5.463zm13 0a.1.1 0 01.124-.097l1.74.435a1.5 1.5 0 011.136 1.456v1.876a1.5 1.5 0 01-1.136 1.456l-1.74.435a.1.1 0 01-.124-.098v-5.463z"
			fill="currentColor"
		/>
	</svg>
))
SupportIconSvg.displayName = 'SupportIconSvg'

const SupportIcon = forwardRef((props, ref) => <Icon component={SupportIconSvg} ref={ref} {...props} />)
SupportIcon.displayName = 'SupportIcon'

SupportIcon.defaultProps = {
	...Icon.defaultProps,
}
SupportIcon.propTypes = {
	...Icon.propTypes,
}

export default SupportIcon
export { SupportIconSvg }
