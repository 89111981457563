/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const AccountIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 3.5a4.5 4.5 0 00-2.31 8.363A7.503 7.503 0 004.5 19v1a.5.5 0 001 0v-1a6.5 6.5 0 1113 0v1a.5.5 0 001 0v-1a7.503 7.503 0 00-5.19-7.137A4.5 4.5 0 0012 3.5zm0 8a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
			fill="currentColor"
		/>
	</svg>
))
AccountIconSvg.displayName = 'AccountIconSvg'

const AccountIcon = forwardRef((props, ref) => <Icon component={AccountIconSvg} ref={ref} {...props} />)
AccountIcon.displayName = 'AccountIcon'

AccountIcon.defaultProps = {
	...Icon.defaultProps,
}
AccountIcon.propTypes = {
	...Icon.propTypes,
}

export default AccountIcon
export { AccountIconSvg }
