import { styled } from '@mui/system'
import BHButton from '../../molecules/button'

const ButtonStyled = styled(BHButton)`
	font-weight: normal;
	min-width: auto;
	padding: 0;
	border: none;
	color: ${({ theme }) => theme.palette.footer.color};
	justify-content: flex-start;
	text-transform: none;
	&:hover,
	&:focus,
	&:active {
		border: 0;
		background-color: transparent;
	}
`

export { ButtonStyled }
