/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const MapPinIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 2.5A7.5 7.5 0 004.5 10c0 .973.448 2.183 1.186 3.494.73 1.297 1.71 2.634 2.701 3.846A51.634 51.634 0 0012 21.302a51.627 51.627 0 003.613-3.962c.99-1.212 1.97-2.549 2.701-3.846.738-1.311 1.186-2.52 1.186-3.494A7.5 7.5 0 0012 2.5zm-.344 19.863l-.002-.002-.005-.004-.018-.018-.068-.066a50.954 50.954 0 01-1.173-1.18 52.637 52.637 0 01-2.777-3.12c-1.01-1.235-2.03-2.622-2.799-3.989C4.052 12.631 3.5 11.236 3.5 10a8.5 8.5 0 0117 0c0 1.236-.552 2.63-1.314 3.984-.77 1.367-1.79 2.754-2.799 3.99a52.637 52.637 0 01-3.95 4.3l-.068.065-.018.018-.007.005L12 22c.344.363.345.362.345.362L12 22.69l-.344-.326zM12 8.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
			fill="currentColor"
		/>
	</svg>
))
MapPinIconSvg.displayName = 'MapPinIconSvg'

const MapPinIcon = forwardRef((props, ref) => <Icon component={MapPinIconSvg} ref={ref} {...props} />)
MapPinIcon.displayName = 'MapPinIcon'

MapPinIcon.defaultProps = {
	...Icon.defaultProps,
}
MapPinIcon.propTypes = {
	...Icon.propTypes,
}

export default MapPinIcon
export { MapPinIconSvg }
