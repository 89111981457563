import MuiSwipeableDrawer from '@mui/material/SwipeableDrawer'
import { styled } from '@mui/system'
import BHButton from '../../molecules/button'

const sidebarWidth = `100vw`

const HamburgerButton = styled(BHButton)`
	padding: 5px;
`

const SwipeableDrawer = styled(MuiSwipeableDrawer)`
	> .MuiPaper-root {
		width: ${sidebarWidth};
		overflow-x: hidden;
		padding: 62px 8px 26px;
		will-change: auto;
		.NavMenu-root {
			min-height: max-content; /* HYDREPLAT-139 */
			width: 100%;
			.NavSubMenu-root {
				width: 100%;
			}
		}
	}
`

const ButtonClose = styled(BHButton)`
	right: 8px;
	top: 8px;

	&.iconClose {
		padding: 6px;
		svg {
			font-size: ${({ theme }) => theme.typography.pxToRem(24)};
		}
	}
`

export { ButtonClose, HamburgerButton, SwipeableDrawer }
